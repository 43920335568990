.commingSoonScreen {
   height: 100vh;
   position: relative;
   backface-visibility: hidden; /*Remove wierd move when animated*/

   
   
   &__logo {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%,-50%);
      animation: fadeInUp 6s 1s ease-in-out;
      animation-fill-mode: backwards;
      padding: 4rem 4rem;
      background-color: none;
      height: 23rem;
      border-radius: .5rem;
      text-align: center;
      width: 75%;


      @include query (max-w-900) {
         width: 75%;

         
      }

      @include query (max-w-480) {
         width: 90%;
      }
      

  
      
   }

   &__title {
      position: absolute;
      top: 87%;
      left: 50%;
      transform: translate(-50%,-50%);
      animation: fadeInDown 4s 3s ease-in-out;
      animation-fill-mode: backwards;
      backface-visibility: hidden;
      font-family: 'Dancing Script', cursive;
      width: 100%;
      text-align: center;

      @include query(min-h-1550) {
         animation: fadeInDown2 4s 3s ease-in-out;
         animation-fill-mode: backwards;
         backface-visibility: hidden;
      }

      @include query(max-w-1115) {
         animation: fadeInDown2 4s 3s ease-in-out;
         animation-fill-mode: backwards;
         backface-visibility: hidden;
      }
      
   }

   &__commingSoon {
      
      position: absolute;
      top: 90%;
      left: 85%;
      transform: translate(-50%,-50%);
      text-align: center;
      width: 100%;
      
      
      @include query(max-w-700) {
         top: 77%;
         left: 50%;
         transform: translate(-50%,-50%);
      }
      
   }

   &__imgContainer img {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      animation: fadeIn 2s ease-in;
      background-position: center;
   }

}


.footer {
   height:100%;
   background-image: linear-gradient(to bottom, $color-primary-light, $color-primary);
   color: $color-white;
   display: flex;
   align-items: center;
   justify-content:space-between;

   @include query (max-w-700) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding-top: 2rem ;
   }



   //LEFT
   &__left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content:center;
      flex-direction: column;

      @include query(max-w-900) {
         width: 40%;
      }

      @include query (max-w-700) {
         width: 90%;
      }

      &--paragraph {
         width: 50%;
         padding: 2rem;
         margin: 2rem;
         border-bottom: 0.1rem solid $color-grey-dark-2;
         font-size: 1.5rem;
         hyphens: auto;
         text-align: center;
         line-height: 1.5;

         @include query(min-h-1550) {
            font-size: 2rem            
         }

         @include query(max-w-900) {
            width: 85%;
         }


      }

      &--copyright {
         padding: 2rem;
         font-size: 1.6rem;

         @include query(min-h-1550) {
            font-size: 2rem            
         }
      }
      
   }


   //RIGHT
   &__right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content:center;

      @include query(max-w-900) {
         width: 60%;
      }

      @include query (max-w-700) {
         width: 90%;
      }

      @include query (max-w-480) {
         flex-direction: column-reverse;
         text-align: center;
      }

      &--paragraph {
         width: 50%;
         text-align: justify;
         line-height: 1.5;
         font-size: 1.6rem;
         hyphens: auto;
         padding: 2rem;;

         @include query(min-h-1550) {
            font-size: 2.4rem
         }

         @include query (max-w-700) {
            width: 90%;
         }

       
      }
      
      
      &--image {
         width: 50%;
         padding: 1rem;
         text-align: center;

         @include query(max-w-480) {
            width: 100%;
         }



         img{
            width: 25rem;
            border: 1rem solid $color-grey-light-1;

            @include query(min-h-1550) {
               width: 30rem;
            }
            
         }
      }

    
   }


}



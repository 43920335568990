

 @keyframes fadeInUp {
   0% {
      opacity: 0;
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: $color-beige-dark;

    }

    50% {
       opacity: 1;

    }

    70% { 
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: $color-beige-dark;

    }

    85% {
      background-color: rgba($color-beige-dark, 0);

      
    }
  
  
    100% {
      top: 20%;
      left: 50%;
      transform: translate(-50%,-50%);     
      background-color: rgba($color-beige-dark, 0);

    }
 }

 @keyframes fadeInDown {
   0% { 
      opacity: 0;
      top: 47%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    40% {
       opacity: 1;

    }

    55% { 
      top: 47%;
      left: 50%;
      transform: translate(-50%,-50%);
      
    }
  
  
    100% {
      top: 87%;
      left: 50%;
      transform: translate(-50%,-50%);

    }
 }


 @keyframes fadeInDown2 {
  0% { 
     opacity: 0;
     top: 44%;
     left: 50%;
     transform: translate(-50%,-50%);
   }

   40% {
      opacity: 1;

   }

   55% { 
     top: 44%;
     left: 50%;
     transform: translate(-50%,-50%);
     
   }
 
 
   100% {
     top: 87%;
     left: 50%;
     transform: translate(-50%,-50%);

   }
}

@keyframes fadeInDown3 {
  0% { 
     opacity: 0;
     top: 42%;
     left: 50%;
     transform: translate(-50%,-50%);
   }

   40% {
      opacity: 1;

   }

   55% { 
     top: 42%;
     left: 50%;
     transform: translate(-50%,-50%);
     
   }
 
 
   100% {
     top: 90%;
     left: 50%;
     transform: translate(-50%,-50%);

   }
}


 @keyframes fadeIn {
    0% {
       opacity: 0;
    }

    100% {
       opacity: 1;
    }
 }

 
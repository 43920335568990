h1 {
   font-family: $logo;
   color: $color-primary;
   font-size: 7.8rem;
   
      
   
   @include query(max-w-1115) {
      font-size: 6.5rem;
   }

   @include query (min-w-900) {
      
      
   }
   
   @include query(max-w-775) {
      font-size: 5.2rem;
   }

   @include query(max-w-480) {
      font-size: 4.5rem;
   }

   @include query(max-w-355) {
      font-size: 4rem;
   }

   @include query(max-w-325) {
      font-size: 3rem;
   }

}

h2 {
   font-size: 4rem;

   @include query(max-w-775) {
      font-size: 3.7rem;
   }

   @include query(max-w-400) {
      font-size: 3.2rem;
   }
}

h3 {
   font-size: 3rem;

}

h4 {
   font-size: 2.4rem;
   animation: fadeIn 3s 7s ease-in-out;
   animation-fill-mode: backwards;

   @include query(min-h-1550) {
      font-size: 2.5rem
   }

  
 
}

h5 {
   font-size: 2.2rem;
   font-weight: 300;
   animation: fadeIn 3s 7.5s ease-in-out;
   animation-fill-mode: backwards;

   @include query(min-h-1550) {
      font-size: 2.2rem
   }

   @include query(max-w-480) {
      font-size: 2rem;
   }
}


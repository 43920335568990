@mixin clearfix {
   &::after {
      content: "";
      display: table;
      clear: both;
   }
}

@mixin translateCenter {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%,-50%)
}

/*
$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px

*/


@mixin query($breakpoint) {

   @if $breakpoint == max-w-325 {
      @media (max-width: 20.31em) { @content } //325px
   }

   @if $breakpoint == max-w-355 {
      @media (max-width: 22.18em) { @content } //355px
   }

   @if $breakpoint == max-w-400 {
      @media (max-width: 25em) { @content } //400px
   }
   
   @if $breakpoint == max-w-480 {
      @media (max-width: 30em) { @content } //480px
   }

   @if $breakpoint == max-w-600 {
      @media (max-width: 37.5em) { @content } //600px
   }

   @if $breakpoint == max-w-700 {
      @media (max-width: 43.75em) { @content } //700px
   }

   @if $breakpoint == max-w-775 {
      @media (max-width: 48.44em) { @content } //775px
   }

   @if $breakpoint == max-w-900 {
      @media (max-width: 56.25em) { @content } //900px
   }

   @if $breakpoint == max-w-1115 {
      @media (max-width: 70em) { @content } //1115px
   }

   @if $breakpoint == max-w-1200 {
      @media (max-width: 75em) { @content } //1200px
   }

   @if $breakpoint == min-w-1800 {
      @media (min-width: 112.5em) { @content } //1800px +
   }

   @if $breakpoint == min-h-1550 {
      @media (min-height: 96.8em) { @content } //1550
   }
}
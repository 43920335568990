* {
   margin: 0;
   padding: 0;
   box-sizing: inherit;
}

html { 
   // Set up for rem use -  10px/16px = 62.5%
   font-size: 62.5%; //  1 rem = 10px

  

  @include query(max-w-1200) {
    font-size: 56.25%  // 1rem = 9px - 9/16 = 56.25%
  }

    @include query(max-w-900) {
      font-size: 50%  // 1 rem = 8px - 8/16 = 50%
    }

    @include query(min-w-1800) {
      font-size: 90%  // 1rem = 12px - 12/16 = 75%
  
    }

   
 }

body {
  //  padding: 3rem;
   box-sizing: border-box;
}


a {
  text-decoration: none;
  color: $color-white;
  margin: 1rem;
}

img {
  background-size: cover;
}


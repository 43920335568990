//COLOURS
$color-primary: #010EFF;
$color-primary-light:#008cff;
$color-primary-dark: #0003c0;

$color-beige-light: #FFFFF4;
$color-beige-dark: #FFF1CC;



$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;




$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2 : #eee;

$color-white: #FFF;
$color-black: #000;


//FONTS

$logo : 'Mr Bedfort', cursive;



//GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;


//FONT SIZES
$default-font-size: 1.6rem